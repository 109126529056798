import React,{useState,useEffect,useContext} from 'react'
import { Box, IconButton, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow, Card,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import moment from 'moment';
import {celda} from '../../Generales/TablaGeneral';
import { printConsole } from '../../funciones/ConsolaLog';
import NuevoAlbum from './NvoAlbum';
import {AddCircleOutline} from '@mui/icons-material';
import EditarAlbum from './EditAlbum';
import CreateIcon from '@mui/icons-material/Create';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import ImageIcon from '@mui/icons-material/Image';
import ModalListArchivo from './ModalListArchivo';
import ModalImg from './ModalArchImg';
import ModalListVideo from './ModalListVideo';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ModalGenero from './ModalGenero';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import {AuthContext} from "../../Generales/AuthContext";
import { findTipoCad } from '../../funciones/FindNumber';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '50rem',
        },
        container: {
            maxHeight: 420,
           // width: '50rem',
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaAlbum = ({datos,setContrato,Buscar,listArchTema,llenarListFile,llenaListBusq,listVideo,llenaListFileTem}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);
    const [modalNvoAlbum,setModalNvoAlbum]= useState(false);
    const [modalEditAlbum,setModalEditAlbum]= useState(false);
    const [modalListArch,setModalListArch]= useState(false);
    const [modalArchImg,setModalArchImg]= useState(false);
    const [modalListVideo,setModalListVideo]= useState(false);
    const [modalGener,setModalGener]= useState(false);
    const {usuario} = useContext(AuthContext);
    const tipoAudio=[1,2,4]
    const tipoVideo=[1,2,5]
    useEffect(() => {
        printConsole(datos)
    
    
    }, [ ])
 
    const actionRow=(row)=>{
        setSelected(row) 
        setContrato(row)
    }

    const CardStatus=({children,color})=>{
        return<Card sx={{background:color}}>
            {children}
        </Card>
    }
    function Row(props) {
        const {row} = props;
        const usrVal=usuario.length !== 0&& usuario.nivel?true:false
        const tpAudio= usrVal && findTipoCad(usuario.nivel,tipoAudio)  ?  false : true
        const tpVideo=usrVal && findTipoCad(usuario.nivel,tipoVideo)  ?  false :true
        return (           
        <TableRow  selected={selected?.Id === row.Id} className={classes.tableRow}    
            classes={{hover: classes.hover, selected: classes.selected}}            
            onClick={()=>actionRow(row)}                     
        >   
            {   celda("center", { width: 45+'px'},       
                <Tooltip title="Editar Álbum">
                <IconButton size="small"   onClick={()=>{setModalEditAlbum(true)}}   >
                    <CreateIcon/>
                </IconButton>              
                </Tooltip>         
            , 1, classes.tableCell)   }                   
            {  celda("center", { width: 45+'px'},       
                <Tooltip title="Subir Pistas">
                    
                <IconButton size="small" onClick={()=>{setModalListArch(true)}}  
                    disabled={row.Total===0? true :tpAudio} >
                    <LibraryMusicIcon color={row.Total===0 ||tpAudio?"inherit":"primary"} />
                </IconButton>              
                </Tooltip>         
            , 12, classes.tableCell)  }        
            {  celda("center", { width: 45+'px'},       
                <Tooltip title="Subir Videos">
                 
                <IconButton size="small" onClick={()=>{setModalListVideo(true)}}  
                    disabled={row.Total===0?true:tpVideo} >
                    <VideoLibraryIcon color={row.Total===0|| tpVideo?"inherit":"primary"} />
                </IconButton>              
                </Tooltip>         
            , 13, classes.tableCell)  }   
             {   celda("center", { width: 45+'px'},       
                <Tooltip title="Subir Portada Álbum">
                <IconButton size="small"   onClick={()=>{setModalArchImg(true) }}   > 
                    <ImageIcon color={row.Img===0?"inherit":"primary"}/>
                </IconButton>              
                </Tooltip>         
            , 14, classes.tableCell)   }       
              {   celda("center", { width: 45+'px'},       
                <Tooltip title="Generos del Álbum">
                <IconButton size="small"   onClick={()=>{setModalGener(true) }}   > 
                    <QueueMusicIcon color={row.Img===0?"inherit":"primary"}/>
                </IconButton>              
                </Tooltip>         
            , 15, classes.tableCell)   }                         
            {celda("center", {width: 45+'px'}, 
                row.Id
            , 2, classes.tableCell)}  
            {celda("center", {}, row.Total, 11, classes.tableCell)}   
            {celda("center", {width: 85+'px'},
            <CardStatus color={row.StatusColor} >
                    {row.StatusNom}
            </CardStatus>
             , 16, classes.tableCell)}        
            {celda("center", {width: 45+'px'}, 
                row.FAlta=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.FAlta).format('DD/MM/YYYY')
            , 3, classes.tableCell)}   
            {celda("center", {width: 45+'px'}, 
                row.FPublica=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.FPublica).format('DD/MM/YYYY')
            , 4, classes.tableCell)}      
            {celda("left", {width: 45+'px',fontSize:12+'px'}, row.TipoNom, 5, classes.tableCell)}
            {celda("center", {}, row.CveCat, 6, classes.tableCell)}            
            {celda("center", {}, row.UPC, 7, classes.tableCell)}        
            {celda("center", {}, row.Codigo, 8, classes.tableCell)}             
            {celda("left", {fontSize:12+'px'}, row.InterNom, 9, classes.tableCell)}
            {celda("left", {fontSize:12+'px'}, row.Titulo, 10, classes.tableCell)}            
                
        </TableRow>                          
        );
    }                        
    
const tablaCompleta = () => {

    return (
    <TableContainer sx={{  height:window.innerHeight/3}}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                {  celda("center", {}, "", 1, "")   }
                {  celda("center", {}, "", 13, "")   }
                {  celda("center", {}, "", 14, "")   }
                {  celda("center", {}, "", 15, "")   }
                {celda("center", {}, datos.length , 12, "") }
                {celda("left", {},  
                 <Tooltip title="Agregar Álbum">
                 <IconButton onClick={()=>{setModalNvoAlbum(true)}} size="small" >
                    <AddCircleOutline />
                </IconButton>
                </Tooltip>
                , 2, "")}                                    
                {celda("center", {}, "Total",11, "")}     
                {celda("center", {}, "Status",16, "")}               
                {celda("center", {}, "F.Alta",3, "")}   
                {celda("center", {}, "F.Publica",4, "")}     
                {celda("center", {}, "Tipo",5, "")}     
                {celda("center", {}, "Clave", 6, "") }     
                {celda("center", {}, "UPC", 7, "")}
                {celda("center", {}, "Codigo",8, "")}
                {celda("center", {}, "Interprete",9, "")}
                {celda("center", {}, "Titulo",10, "")}
                                                                                                    
            </TableRow>
        </TableHead>
        <TableBody>                                                                 
            {datos.length !== 0  && datos[0].Id!==0? 
                (datos.map((row,index) => (
                    <Row key={index} row={row}/>
                ))) 
            : 
            <TableRow className={classes.tableRow}  >          
                {celda("center", {}, "", 15, classes.tableCell)}  
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "", 2, classes.tableCell)}
                {celda("center", {}, "", 3, classes.tableCell)}
                {celda("center", {}, "Sin", 4, classes.tableCell)}
                {celda("center", {}, "", 5, classes.tableCell)}
                {celda("center", {}, "", 12, classes.tableCell)}
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "Registros", 7, classes.tableCell)}
                {celda("center", {}, "", 8, classes.tableCell)}
                {celda("center", {}, "", 9, classes.tableCell)}
                {celda("center", {}, "", 10, classes.tableCell)}
                {celda("center", {}, "", 11, classes.tableCell)}
                {celda("center", {}, "", 13, classes.tableCell)}
                {celda("center", {}, "", 14, classes.tableCell)}
            </TableRow>       
            }
        </TableBody>
    </Table>
    </TableContainer>
    )
    }

    const modNvoAlbum= modalNvoAlbum ? <NuevoAlbum modalAbierto={modalNvoAlbum} Buscar={Buscar}
                                                setModalAbierto={setModalNvoAlbum} registro={selected} /> :null
    const modEditAlbum= modalEditAlbum ? <EditarAlbum modalAbierto={modalEditAlbum} Buscar={Buscar}
                                                setModalAbierto={setModalEditAlbum} registro={selected} /> :null    
    const modListArch = modalListArch ? <ModalListArchivo modalAbierto={modalListArch} listArchTema={listArchTema}
                                            setModalAbierto={setModalListArch} registro={selected} llenarListFile={llenarListFile} /> :null        
    const modListVideo = modalListVideo ? <ModalListVideo modalAbierto={modalListVideo} listVideo={listVideo} listTema={listArchTema}
                                            setModalAbierto={setModalListVideo} registro={selected} llenaListFileTem={llenaListFileTem} /> :null                                                                                                                                        
    const modImg = modalArchImg ? <ModalImg modalAbierto={modalArchImg} setModalAbierto={setModalArchImg} Buscar={llenaListBusq} registro={selected} />
                                :null         
    const modGen= modalGener ? <ModalGenero modalAbierto={modalGener} setModalAbierto={setModalGener} registro={selected} />:null
  return (
    <Box>
        {modGen}
        {modImg}
        {modListVideo}
        {modListArch}
        {modNvoAlbum}
        {modEditAlbum}
        {tablaCompleta()}
    </Box>
  )
}

export default TablaAlbum