import {Box, Grid, TextField} from "@mui/material";
import FormDialogInfo from '../../Layout/FormDialogInfo'
import {useState} from "react";
const ViewerPDF = ({modalAbierto, setModalAbierto, registro}) => {
  function generarVisualizador(){
    let elemento=<div></div>
    if(registro.Pdf)
    {
      elemento=
      <object data={registro.Pdf } type="application/pdf" style={{width:48+'rem'}} height={'700px'}>
          
      </object>
    }
    return elemento;
}
  return (
    < FormDialogInfo tamanio={'md'}
    titulo={ `${registro.AlbumNom} - ${registro.Titulo}`}
    modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto}
    
    bgTitle={false}
    >
        <Box style={{width:25+'rem'}} >        
        {generarVisualizador()}    
   </Box>
</FormDialogInfo>
  )
}

export default ViewerPDF