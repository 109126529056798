import React,{useState,useEffect} from 'react'
import { Box, IconButton, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow, Typography,  Button,  
    Backdrop ,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import {toast} from 'react-toastify';
import {celda} from '../../Generales/TablaGeneral';
import { useNavigate } from "react-router-dom";
import {llamaApiCargaToken,ErrorGeneral } from '../../funciones/ClienteAxios';
//import { printConsole } from '../../funciones/ConsolaLog';
//import {AddCircleOutline} from '@mui/icons-material'; 
import ModalListTema from './ModalListTema';
import RegistroTema from './RegistroTema';
import CreateIcon from '@mui/icons-material/Create';
import CircularProgress from '@mui/material/CircularProgress';
import ModalListArchivo from './ModalListArchivo';

const useStyles = makeStyles()((theme) => {
    return { 
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaArchivos = ({datos,contrato,llenarListFile,llenaListBusq}) => {
    const classes = useStyles();
    const history = useNavigate();
    const source = axios.CancelToken.source();   
    const idusu= localStorage.getItem('UsuId');    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const [selectedID, setSelectedID] = useState(null);     
    const [modalListaTema,setModalListaTema]= useState(false);
    const [bandera,setBandera] = useState(0)
    const [contador,setContador] = useState(1)
    const [desactivado,setDesactivado] = useState(true)
    const [modalListArch,setModalListArch] = useState(false)
    const hoy=moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    const hoy2=moment(new Date()).format('YYYY-MM-DD')
    const general={ pista:1, estatus:1, Id:1,
        fAlta:hoy, fPublica:hoy2, 
        isrc:"", ft:"", titulo:"", 
        autor:"", editora:"",  duracion:"00:00"
    }
    const [lastPista,setLastPista] = useState(1)
    const [arraySubidos,setArraySubidos] = useState([])
    const [listTema,setListTema]=useState([]);
    const [espera,setEspera]=useState(false);
    useEffect(()=>{    
        let nvo=[]        
        let auxContador=0 ,auxContador2=0
        if (datos.length!==0 && datos[0].Id>0) {
            
        
        datos.forEach((tema)=>{
            if (tema.Id>auxContador) {
                auxContador=tema.Id
            }
            if (tema.Pista> auxContador2 ) {
                auxContador2=tema.Pista
            }
            nvo.push({
                pista:tema.Pista, estatus:1, Id:tema.Id,
                fAlta:tema.FAlta.substring(0,19), 
                fPublica:tema.FPublica.substring(0,10), 
                isrc:tema.ISRC, ft:tema.Ft, titulo:tema.Titulo, 
                autor:tema.Autor, editora:tema.Editora,  duracion:tema.Duracion
            })                         
        })  
        }       
        else{
            nvo.push( general )            
            auxContador2=lastPista   
            auxContador=contador              
        }        
        setLastPista(auxContador2)
        setContador(auxContador)
        setListTema(nvo)
        return ()=> {
            source.cancel();
        }     
        
    },[]);

    const addList=()=>{
        const modificado={...general,pista:lastPista+1,Id:contador+1}
        listTema.push(modificado)                
        setContador(contador+1)
        setLastPista(lastPista+1)
    }
    const tablaCompleta = () => {

        return (
        <TableContainer sx={{ height:window.innerHeight/3,}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>        
                    {celda("center", {}, "Estatus" , 9, "") }
                    {celda("center", {}, "Pista" , 1, "") }
                    {celda("center", {},"ISRC", 2, "")}                                           
                    {celda("center", {}, "FT",3, "")}   
                    {celda("center", {}, "Fecha Publicación",4, "")}     
                    {celda("center", {}, "Titulo",5, "")}     
                    {celda("center", {}, "Autor", 6, "") }     
                    {celda("center", {}, "Editora", 7, "")}
                    {celda("center", {}, "Duración",8, "")}                                                                                                  
                </TableRow>
            </TableHead>
            <TableBody>                                                                 
            {listTema.length !== 0  ? 
                (listTema.map((row,index) => (                
                    <RegistroTema key={index} row={row} bandera={bandera}
                    datos={listTema} setDatos={setListTema} activo={desactivado} />
                ))) 
            : 
            <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "", 2, classes.tableCell)}
                {celda("center", {}, "", 3, classes.tableCell)}
                {celda("center", {}, "Sin", 4, classes.tableCell)}
                {celda("center", {}, "", 5, classes.tableCell)}
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "Registros", 7, classes.tableCell)}
                {celda("center", {}, "", 8, classes.tableCell)}    
            </TableRow>       
            }
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    
    const llenarListEdit = (auxList) => {                   
    
        let data = qs.stringify({
            Album:contrato.Id,
            Usuario:idusu,
            Temas:JSON.stringify(auxList),
            idDispositivo: auxDispV,
        });
       
        let url = "album-tema-new-edit";
        function respuesta(auxiliar){            
          if ( auxiliar) 
          {                 
           // Buscar()
            llenarListFile(2)
            llenaListBusq(2)
            setDesactivado(true)
            let mensaje="Se edito correctamente la lista"        
            toast.success(`${mensaje}`,{
              pauseOnFocusLoss: false,
              theme: "colored", toastId: `exito-env${contrato.Id}`
            })  
                
          }     
            
        }
       llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)        
    };  

    const guardarDatos = () => 
    {    
        setBandera(bandera+1)        
        setTimeout(() => {
            if (listTema.length!==0) 
            {   let nvo=[], valido=true,mensaje=""
                listTema.forEach((element) => {

                    if (element.pista !== "" && element.pista!==0 && element.pista!=="0")
                    {   if (element.titulo!==""&& element.titulo.length>=2) 
                        {
                            if (element.autor!==""&& element.autor.length>=2) 
                            {
                                nvo.push({
                                    pista:element.pista, estatus:1,  
                                    fAlta:element.fAlta , 
                                    fPublica:element.fPublica +"T00:00:00", 
                                    isrc:element.isrc, ft:element.ft, 
                                    titulo:element.titulo, autor:element.autor, 
                                    editora:element.editora, duracion:element.duracion
                                })        
                            }
                            else
                            {   valido=false
                                mensaje='Debe llenar los campos de Autor y debe ser mayor a 2 caracteres'      
                                
                            }    
                        }
                        else
                        {   valido=false
                            mensaje='Debe llenar los campos de Titulo y debe ser mayor a 2 caracteres'      
                            
                        }                            
                    }
                    else
                    {   valido=false
                        mensaje='Debe llenar los campos de Pista y debe ser mayor a 0'      
                        
                    }                    
                      
                });     
                if (valido) 
                {
                    llenarListEdit(nvo);      
                    
                }            
                else
                {
                    toast.warning(`${mensaje}`,{
                        pauseOnFocusLoss: false, theme: "colored",
                        toastId: `error-list-tema ${1}`
                    })
                }   
                  
            }   
            else{
                let mensaje=`Debe seleccionar un archivo para subir`
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel`
                }); 
            }        
        }, 100);
        
    };

    const btnCambiar=()=>{
        return(
        <span style={{display:"flex",marginLeft:'1rem'}}>
            <Typography>
                ¿Esta seguro que desea cambiar la lista de Tema?
            </Typography>
            <Button variant="outlined" onClick={guardarDatos} size="small"
                style={{marginLeft:'.7rem'}}
            >
                Enviar
            </Button>
        </span>
        )
    }

    const btnArch = <Button variant="outlined" onClick={()=>setModalListArch(true)} size="small">Lista</Button>
    const btnEditar=<Tooltip title="Editar Temas"><IconButton  size="small" onClick={()=>setDesactivado(!desactivado)}>
        <CreateIcon/> </IconButton></Tooltip>
    const btnMas = <Button variant="outlined" onClick={addList} size="small">Agregar Tema</Button>
    const modListTema = modalListaTema?    <ModalListTema modalAbierto={modalListaTema} setModalAbierto={setModalListaTema} 
                                                titulo={`Temas de ${contrato.Titulo}`} listTemas={datos} />:null                                             
    const modListArchivo= modalListArch ? <ModalListArchivo modalAbierto={modalListArch} llenarListFile={()=>{}}
                                                setModalAbierto={setModalListArch} registro={contrato} /> :null                                                

  return (
    <Box>
        {modListArchivo}
        {modListTema}
        <div style={{display:"flex"}}>
            <Typography color="primary" sx={{marginRight:.5+'rem'}}>
                {`Temas del Album ${contrato.Titulo}`}
            </Typography>
            {btnEditar}
            {!desactivado?btnMas:null}
            {!desactivado?btnCambiar():null}
            {/* btnArch */}
        </div>
        {tablaCompleta()}
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={espera}        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
   </Box>
  )
}

export default TablaArchivos