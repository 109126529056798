import {Box,} from "@mui/material";
import FormDialogInfo from '../../Layout/FormDialogInfo'
import { Player } from 'video-react';
import Reproductor from './Reproductor'
import ShowAudio from "./ShowAudio";
const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) 
  {
    return "Android"
  }
  else 
  {
    if( (/iPad|iPhone|iPod/.test(ua))
     || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
    {
      return "iOS"
    }
  }
  return "Other"
}
const listTipoAudio=[{tipo:"audio/mp4"} ,{tipo:"audio/mpeg"} ,{tipo: "audio/wav"},{tipo: "audio/ogg"},{tipo: "audio/mp3"} ,{tipo: `audio/x-m4a`}]
const MediaTema = ({modalAbierto, setModalAbierto, registro}) => { 
  let auxLink= "https://titanio-records.sfo3.digitaloceanspaces.com/album/audio/10/221230/7b3ada05-201b-4e55-8daf-d876f2003f4d.mp3"
  //let auxLink2= "./Rollin_ On.mp3"
  const os = getMobileOS()


  const audioReprod=(archivo,ancho)=>{
    return(
    <audio controls autoplay id="miAudio" style={{height:1.5+'rem',width:ancho}} >
        {listTipoAudio.map((formato)=>(
            <source src={archivo} type={ formato.tipo} />     
        ))}                                
    </audio>
    )
  }

  return (
  < FormDialogInfo tamanio={'md'} bgTitle={false}
    titulo={ `${registro.AlbumNom} - ${registro.Num} ${registro.Titulo}`}
    modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}        
  >
    <Box style={{width:'100%'}} >  
      
      {/*   <ShowAudio source={auxLink}/>   */} 
      { registro.Links.map((liga )=>{
        if (liga.Tipo==="AUDIO") 
        { console.log(os);
          if (os!=='iOS') 
          {//liga?.Link
            return(<Reproductor link={liga?.Link} ext={liga?.Ext} />)  
          }
          else
          {
            return(
            <section>          
                
              <Box sx={{ display: {   xs: 'block' , sm: 'none'} }} >
                { audioReprod( liga?.Link,"16.5rem") }
                {/* <Reproductor link={liga?.Link} ext={liga?.Ext} /> */}
              </Box>
              <Box  sx={{ display: {   xs: 'none' , md: 'block'} }} >
                {audioReprod(liga?.Link,'18rem')}                           
              </Box> 
              
            </section>
            ) 
          }
                      
        }
        else
        { if (liga.Tipo==="VIDEO") 
          {
            return(          
              <div style={{borderRadius:.5+'rem'}}              >
            <Player src={liga?.Link} 
              playsInline poster=""
              style={{borderRadius:.5+'rem'}}    
            />     
            </div>       
            )
          }
        }
      }) 
      }      
    </Box>
  </FormDialogInfo>
  )
}

export default MediaTema