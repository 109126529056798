import React,{createContext,useState} from 'react';

export const GeneralContext = createContext();

const GeneralProvider = (props) => {
    const [generales,setGenerales]=useState([])
    return (
    <GeneralContext.Provider
        value={{
            generales,setGenerales
        }}
    >
        {props.children}
    </GeneralContext.Provider>
    );
}

export default GeneralProvider;