import React from "react";
import { makeStyles } from "tss-react/mui";
import {LinearProgress, Box, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    fuenteTamanio:{
        fontSize:15,
        height:'2em',
    },
}));

export default function Progress({progress}){
    const classes = useStyles();
    return(
    <Box sx={{ display: 'flex', alignItems: 'center',width:'55%'}}>
        <Box sx={{ width: '90%'}}>
            <LinearProgress variant="determinate" value={progress}/>
        </Box>
        <Box sx={{ width: '15%', marginLeft:'.3em'}}> 
            <Typography className={classes.fuenteTamanio}>{progress+"%"}</Typography>
        </Box>
    </Box>
)
}