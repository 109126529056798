import React,{useState} from 'react';
import {IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import NewEditUsu from "./NewEditUsu";

const TableUsers = ({abrirModalNuevo, usuarios,  handleEditarEstatus, getListUsu}) => {
    const [modalEdit, setModalEdit] = useState(false);   
    const [selectedRow, setSelectedRow] = useState([])

    const handleEditar = (id) => {
        let row = usuarios.find(x => x.Id === id)
        setSelectedRow(row)       
        setModalEdit(true)
    }

    const Renglon=({row, index})=>{
        return(
        <TableRow hover key={index} sx={{
            '&:hover .MuiTableCell-root': {
                backgroundColor: (theme) => theme.palette.secondary.light,
                color: (theme) => theme.palette.secondary.contrastText,
            }, '&:last-child td, &:last-child th': {border: 0},
        }}>
            <TableCell component="th" scope="row" align={'center'} sx={{padding: '0.2rem'}}>
                <Tooltip title={'Editar Usuario'} arrow   placement="top">
                    <IconButton onClick={() => handleEditar(row.Id)} size={'small'} color={'inherit'}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.Usuario === '' ? 'tomato' : null }}
            >
                {row.Usuario === '' ? 'Sin Usuario' : row.Usuario}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.Nombre === '' ? 'tomato' : null }}
            >
                {row.Nombre === '' ? 'Sin Nombre' : row.Nombre}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'}
                sx={{ padding: '0.2rem', color: row.TipoNom === '' ? 'tomato' : null}}
            >
                {row.TipoNom === '' ? 'Sin Tipo' : row.TipoNom}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'} 
                sx={{ padding: '0.2rem', color: row.Email === '' ? 'tomato' : null}}
            >
                {row.Email === '' ? 'Sin Email' : row.Email}
            </TableCell>
            <TableCell component="th" scope="row" align={'center'} 
                sx={{ padding: '0.2rem', color: row.Status === -1 ? 'tomato' : null }}
            >
                {row.Status === -1 ? 'Usuario inhabilitado' 
                :<Tooltip title={'Cambiar Estatus'} arrow placement="top">
                    <Switch
                        checked={row.Status === 1 ? true : false}
                        size={'small'} onChange={() => handleEditarEstatus(row.Id)}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                </Tooltip>}
            </TableCell>
        </TableRow>
        )
    }

    const modEdit = modalEdit ?<NewEditUsu modalAbierto={modalEdit} setModalAbierto={setModalEdit} 
                                editUsu={selectedRow} getListUsu={getListUsu} />:null
    return (<>
    {modEdit}
    <TableContainer component={Paper} sx={{maxHeight: '80vh'}}>
        <Table sx={{width: '100%'}} aria-label="usuarios table">
            <TableHead sx={{ backgroundColor: (theme) => theme.palette.secondary.light, padding: '0.2rem'}}>
                <TableRow>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}> 
                        <Tooltip title={'Nuevo Usuario'} arrow placement="top">
                            <IconButton onClick={abrirModalNuevo} color={'inherit'} size={'small'}>
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText}}>
                        Usuario
                    </TableCell>
                    <TableCell align="center" sx={{padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText}}>
                        Nombre
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Tipo
                    </TableCell>
                    <TableCell align="center" sx={{padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Email
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText }}>
                        Estatus
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {usuarios.length === 0 ? 
                    <TableRow hover sx={{
                        '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                            backgroundColor: (theme) => theme.palette.secondary.light,
                            color: (theme) => theme.palette.secondary.contrastText,
                        }, '&:last-child td, &:last-child th': {border: 0},
                        }} 
                    >
                        <TableCell component="th" scope="row" sx={{padding: '0.2rem', color: 'red'}}
                            colSpan={"100%"} align={'center'}>
                            <h4>No existen datos </h4>
                        </TableCell>
                    </TableRow>

                : usuarios.map((row, index) => <Renglon row={row} index={index} />)}
            </TableBody>
        </Table>
    </TableContainer>
    </>
    );
};

export default TableUsers;