import React,{useState,useEffect} from 'react'
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {TextField,Stack, Grid , }from '@mui/material';
import FormDialog from '../../Layout/FormDialog'
import { ErrorGeneral,llamadaApiCarga } from '../../funciones/ClienteAxios'; 
import {toast} from 'react-toastify';
const NuevoSello = ({modalAbierto, setModalAbierto, Buscar}) => {
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const history = useNavigate();
  const usuId =localStorage.getItem('UsuId') ;  
  const source = axios.CancelToken.source();            
  const [espera,setEspera]= useState(false); 
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)  
  const [contadorToast,setContadorToast]= useState(0)  
  const [contrato,setContrato]= useState({ Nombre:"", Alias:"", Tel:"",
    Email:"", Representa:'',
 })
  const{  Representa, Nombre, Alias, Tel, Email}=contrato

  useEffect(()=>{
    return () => {
      source.cancel();
    }
  },[])
  
  const llamadaApi = () => {                   
    
    let data = qs.stringify({
        Usuario:usuId, Nombre:Nombre, Alias:Alias, 
        Representa:Representa, Tel:Tel, Email:Email,
        idDispositivo: auxDispV,
    });
    
    let url = "contrato-sello-new";
    function respuesta(auxiliar){            
      if ( auxiliar) 
      {                 
        Buscar()
        let mensaje="Se creo correctamente el registro"        
        toast.success(`${mensaje}`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-env`
        })  

        setModalAbierto(false)    
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
  };  
 
  const onChange=(e)=>{    
    
    setContrato({...contrato,[e.target.name] : e.target.value})
      
  }
  const onChangeTel=(e)=>{        
    
    let expNopermitida = new RegExp('[#A-Za-z._:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
      !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
      ||e.target.value===' '||e.target.value==='') 
    {
      setContrato({...contrato,[e.target.name] : e.target.value})     
    }       
  }

  const verificaNulos = (campo,descripcion)=>{
    let respuesta =false;              
    if( (typeof campo !== 'number' && typeof campo === 'string' 
        && (campo.trim() === '' || campo.trim() === null) )
    ) 
    { let mensaje='No se lleno el campo '+descripcion      
      toast.warning(`${mensaje}`,{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `error-env ${contadorToast}`
      })  
      setContadorToast(contadorToast+1)
      respuesta =true
    }    
    return(respuesta);
  }

  const guardarDatos=()=>{    
    if ( !verificaNulos(Nombre,"Nombre") && !verificaNulos(Alias,"Alias") )      
    {      
      if (Representa.length>=3 ||  !verificaNulos("","Representa")) 
      {
        if ( ( Tel.length===10  ||  !verificaNulos("","Teléfono") ) 
          && ( Email.length>=3  ||  !verificaNulos("","Email") ) ) 
        {         
          llamadaApi()
        }
      }
    }
    
  }
  const inputProps = {
    maxlength: "10"
  };
  return (
  <FormDialog
    titulo="Nuevo Sello" modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tamanio="md" btnDeshabilitado={false} cargando={espera}
    setCargando={setEspera}
  >
    <Stack component="form" noValidate spacing={2} sx={{width:"28rem"}}> 
      <TextField
        label="Nombre" id="Nombre-campo"
        value={Nombre} name="Nombre"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />  
      <TextField
        label="Alias" id="Alias-campo"
        value={Alias} name="Alias"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />  
      <TextField
        label="Representa" id="representa-campo"
        value={Representa} name="Representa"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />  
      <Grid container direction={"row"} > 
      <TextField  sx={{width:"30%",marginRight:1+'rem',}}
        id="outlined-Tel" label="Teléfono" inputProps={inputProps} 
        name="Tel" onChange={onChangeTel}
        value={Tel} size='small'
      />
      <TextField
        id="outlined-Email" label="Email"
        name="Email" onChange={onChange}
        value={Email} size='small' sx={{width:"66%"}}
      />
      </Grid>
    </Stack>
  </FormDialog>
  )
}

export default NuevoSello