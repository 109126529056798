import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  TextField,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Search as SearchIcon } from "@mui/icons-material/";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import BusquedaTabs from "./BusquedaTab";
import { ErrorGeneral, llamaApiCargaToken } from "../../funciones/ClienteAxios";
//import moment from 'moment';
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 28,
    },
    title2: {
      fontSize: 18,
    },
    subTitle2: {
      fontSize: 12,
    },
    pos: {
      marginBottom: 12,
    },
    addButton: {
      marginRight: theme.spacing(1),
    },
    altura2: {
      height: 70,
      //width:'100%',
      marginTop: 0,
      paddingTop: 0,
      // width:275
    },
    altura3: {
      height: 145,
      marginTop: 0,
      paddingTop: 0,
      // width:275
    },
    altura1: {
      height: 90,
    },
  };
});

const Busqueda = () => {
  const { classes } = useStyles();
  const source = axios.CancelToken.source();
  const history = useNavigate();
  const [filtro, setFiltro] = useState({ registro: "" });
  const [espera, setEspera] = useState(false);
  const [datos, setDatos] = useState([]);
  const { registro } = filtro;

  useEffect(() => {
    if (registro.length!==0) {
      llenaListBusq();  
    }
    
    return () => {
      source.cancel();
    };
  }, []);

  const llenaListBusq = () => {
    let data = qs.stringify({
      Usuario: 1,
      Cadena: registro,
      Tipo: 0,
      idDispositivo: "89y7gttvjhj98h",
    });
    let url = "busqueda-filtro-tipo";

    function respuesta(auxiliar) {
      if (auxiliar[0].Id !== -1) {
        setDatos(auxiliar[0]);
      }
    }

    llamaApiCargaToken(
      data,
      url,
      respuesta,
      ErrorGeneral,
      setEspera,
      history,
      source
    );
  };

  const onChange = (e) => {
    let expNopermitida = new RegExp("[#._:$!%-({})/*=?|]");
    let expMenos = new RegExp("-");
    let expMas = new RegExp("[+]");
    let expCadena = new RegExp("[A-Za-z0-9]");
    if (
      (expCadena.test(e.target.value) &&
        !expNopermitida.test(e.target.value) &&
        !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value)) ||
      e.target.value === " " ||
      e.target.value === ""
    ) {
      setFiltro({
        ...filtro,
        [e.target.name]: e.target.value,
      });
    }
  };

  const Buscar = () => {
    if (registro.length >= 3) {
      llenaListBusq();
    }
  };
  const llamadaxEnter=(event)=>{
    if (event.keyCode === 13 && registro.length>=1) 
    {   llenaListBusq( )            
    }
}
  return (
    <Box style={{ padding: 0, margin: 0 }}>
      <Card className={classes.altura2}>
        <CardContent>
          <Box display="flex" flexDirection="row">
            <Box pr={1}>
              <IconButton
                color="primary"
                aria-label="add circle"
                size="small"
                component="span"
                style={{ marginTop: 0.7 + "rem" }}
                onClick={Buscar}
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                size="small"
                style={{
                  width: 20 + "rem",
                  marginBottom: 8,
                  height: 1 + "rem",
                }}
                name="registro"
                value={registro}
                onChange={onChange} //disabled
                onKeyUp={(event)=>  llamadaxEnter(event)}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box>
        {espera && datos.length === 0 ? (
          <Box sx={{marginTop:1+'rem'}}> <LinearProgress /></Box>
        ) : (
          <BusquedaTabs datos={datos} />
        )}
      </Box>
    </Box>
  );
};

export default Busqueda;
