import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead,
    Tooltip, IconButton } from '@mui/material';
export const vacia=(key) => <TableCell  align="center" key={key}> </TableCell>

export const celda=(alinear,estilo,contenido,key,classes)=>{
    return (
    <TableCell align={alinear} key={key} className={classes}
        padding={'none'} style={{...estilo,padding:.2+'rem'}}   //style={...estilo,padding:1+'px'}
    >
      {contenido}
    </TableCell>
    )
}

export const celdaPadding=(alinear,estilo,contenido,key,classes)=>{
  return (
  <TableCell align={alinear} key={key} className={classes}
    style={{...estilo,padding:.2+'rem'}}
  >
    {contenido}
  </TableCell>
  )
}

export const celdaIconoFuncion=(leyenda,funcionModal,childrenIco,registro)=>{//incompleta
    let auxCont=<Tooltip title={leyenda}>
    <IconButton   aria-label="add circle" size="small" onClick={() => funcionModal(registro)} component="span">
      {childrenIco }
    </IconButton>
  </Tooltip>
    return(
      celda("center",{width:10+'px'}, auxCont)
    )
}

export const celdaIcono=(leyenda,childrenIco,key,clase)=>{
    let auxCont=<Tooltip title={leyenda}>{childrenIco }</Tooltip>
    return(
      celda("center",{width:10+'px'}, auxCont,key,clase)
    )
}

export const TablaGeneral = (cuerpo,cabeza,estiloCont) => {
    return (
    <TableContainer className={estiloCont}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                {cabeza}
            </TableHead>
            <TableBody>
                {cuerpo}
            </TableBody>
        </Table>
    </TableContainer>
    );
};
