import React,{useState,useEffect} from 'react'
import { Table, TableBody, IconButton,
    TableContainer, TableHead, TableRow,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import moment from 'moment';
import {celda} from '../../Generales/TablaGeneral';
import { printConsole } from '../../funciones/ConsolaLog';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalViewerPDF from './ViewerPDF';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 420,
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: theme.palette.primary.light
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaContratoMusic = ({datos}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [seleccion, setSeleccion] = useState(null);
    const [modalImg, setModalImg] = useState(false); 
    const abrirModalImg=(valor)=> {
        
        setModalImg(true)
        setSeleccion(valor)
    }

    useEffect(() => {
        printConsole(datos)
    
    
    }, [ ])
 
    function Row(props) {
        const {row} = props;        
        return (           
        <TableRow
            onClick={() => { setSelectedID(row) }}
            selected={selectedID?.Id === row.Id} className={classes.tableRow}
            classes={{hover: classes.hover, selected: classes.selected}}
        >
            {celda("center", {}, 
               row.Pdf.length!==0?
               <a href={`javascript:window.open('${row.Pdf}');`}> ​​​​​​​​ 
                <IconButton size="small"  >
                    <PictureAsPdfIcon/>
                </IconButton></a>:null
            ,6, classes.tableCell)}  
            
            {/* celda("left", { }, row.Representante, 2, classes.tableCell) */}            
            {celda("center", {width: 45+'px'}, 
                row.Fini=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Fini).format('DD/MM/YYYY')
            , 4, classes.tableCell)}  
            {celda("center", {width: 45+'px'}, 
                row.Ffin=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Ffin).format('DD/MM/YYYY')
            , 5, classes.tableCell)}      
            {celda("center", {width: 45+'px'}, 
                row.Ffirma=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Ffirma).format('DD/MM/YYYY')
            , 6, classes.tableCell)}                             
            {celda("center", {}, row.SelloNom, 7, classes.tableCell)}
            {celda("left", {}, row.Descr, 1, classes.tableCell)}
            {celda("left", {}, row.Observ, 3, classes.tableCell)}
        </TableRow>                          
        );
    }

const tablaCompleta = () => {

    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                {celda("left", {}, "", 1, "")}     
                           
                {/* celda("center", {}, "Representante", 3, "") */}
                {celda("center", {}, "F.Inicio",2, "")}   
                {celda("center", {}, "F.Fin",3, "")}     
                {celda("center", {}, "F.Firma",4, "")}     
                {celda("center", {}, "Sello", 7, "")}
                {celda("center", {}, "Descripción", 5, "")}
                {celda("center", {}, "Observaciones",6, "")}
                                                                                            
            </TableRow>
        </TableHead>
        <TableBody>                                                                 
            {datos.contrato_music.length !== 0 && datos.contrato_music[0].Id !== - 1 ? 
                (datos.contrato_music.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) 
            : 
            <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "Sin", 2, classes.tableCell)}
                {celda("center", {}, "", 3, classes.tableCell)}
                {celda("center", {}, "Registros", 4, classes.tableCell)}
                {celda("center", {}, "", 5, classes.tableCell)}
                {celda("center", {}, "", 7, classes.tableCell)}
            </TableRow>       
            }
        </TableBody>
    </Table>
    </TableContainer>
    )
}
const modImg = modalImg? <ModalViewerPDF modalAbierto={modalImg} setModalAbierto={setModalImg} registro={seleccion}/>:null
  return (
    <>
        {tablaCompleta()}
        {modImg}
    </>
  )
}

export default TablaContratoMusic