import {Dialog,DialogTitle,DialogContent,
    DialogActions,IconButton, Tooltip} from '@mui/material';
import {preventClose} from "../Generales/Tools";
import CloseIcon from '@mui/icons-material/Close';
//import {green} from "@mui/material/colors";

export default function FormDialogInfo({    titulo, children,modalAbierto,
                                       setModalAbierto, tamanio,bgTitle
                                   }) {

    const handleClose = (event) => {
        event.preventDefault()
        setModalAbierto(false);        
    };

    return (
    <Dialog open={modalAbierto} maxWidth={tamanio}
        onClose={(event, reason) => preventClose(event, reason, setModalAbierto)} >
        <DialogTitle
            sx= { bgTitle ? 
                {m: 0, p: 2, backgroundColor: (theme) => theme.palette.primary.main, color: 'white'} 
                : { m: 0, p: 2, mr: 5, color: (theme) => theme.palette.primary.main }}
        >
            {titulo}
            <Tooltip title={'Cerrar'}>
                <IconButton aria-label="close" onClick={handleClose}
                    sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
                >
                    <CloseIcon/>
                </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent dividers={true}>
            {children}
        </DialogContent>
        <DialogActions />           
    </Dialog>);
}