import React,{useState,useEffect} from 'react';
import { makeStyles } from 'tss-react/mui';
import {Box ,Card,Typography,// Button, TextField, IconButton, 
        Tooltip,Grid} from '@mui/material';
import axios from 'axios';
import Progress from "../../Generales/Progress";
import { useNavigate } from "react-router-dom";
import FormDialog from '../../Layout/FormDialog'
import {toast} from 'react-toastify';
import {apiFDataHeader } from '../../funciones/ClienteAxios';
import { v4 as uuidv4 } from 'uuid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Player } from 'video-react';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      },         
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const ModalListVideo = ({modalAbierto,setModalAbierto,registro,listVideo,llenaListFileTem,listTema}) => {
    const history = useNavigate();
    const classes = useStyles();
    const source = axios.CancelToken.source();      
    const idusu= localStorage.getItem('UsuId');    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const [espera,setEspera]=useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');  
    const [aviso, setAviso] = useState(false);      
    const [arraySubidos,setArraySubidos] = useState([])
    const [arrayPreview,setArrayPreview] = useState([])        
    const [listTemaMod,setListTemaMod] = useState([])        
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [nombreArchivo, setNombreArchivo] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const listTipoAudio=[{tipo:"video/mp4"} ,{tipo:"video/flv"} ,{tipo: "video/avi"}]
    useEffect(()=>{ 
        let nvo=[]            
        listTema.forEach((tema)=>{
            let filtro=listVideo.filter((video)=>(video.Pista===tema.Pista && video.Tipo==="VIDEO" ))            
            nvo.push({...tema,LinkVideo:filtro.length!==0 ?filtro[0].Link:"" })
        })
        setListTemaMod(nvo)
        return ()=> {
            source.cancel();
        }     
        
    },[listTema,listVideo]);

    
    const llenarListEvid = ( ) => {     
        let auxSize=0
        let auxNom="",lista=[]
        let bodyFormData = new FormData();
        arraySubidos.forEach((img)=>{
            auxSize=auxSize+img.archivo.size
            bodyFormData.append('multi-files',img.archivo)
            auxNom=img.archivo.name
            const filtrado=listTemaMod.filter((tema)=>tema.Id===img.Id)
            
            lista.push({"id":filtrado[0].Id, "pista":filtrado[0].Pista, "nombre":auxNom, "alias":img.alias})
        })              
       // const lista=[{"id":14, "pista":6, "nombre":auxNom, "alias":}]
        const headers = {
            "size":auxSize, "tipo":"SPACE",
            "clase": "ALBUM-VIDEO",
            "llave":registro.Id,
        }           
        
        bodyFormData.append('idDispositivo',auxDispV)
        bodyFormData.append('Llave',registro.Id)
        bodyFormData.append('Usuario',idusu)
        bodyFormData.append('Lista',JSON.stringify(lista))
        console.log(lista);                       
        let url = "album-tema-envio-file";
        function respuesta(auxiliar) 
        {
            if (auxiliar.success===true) 
            {    console.log(auxiliar);
                setProgresoEnvio(0)
                let mensaje="Se envio correctamente el archivo "
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                setAviso(true)   
                llenaListFileTem()  
                auxiliar.archivos.forEach(element => {
                    toast.success(`${mensaje} ${element.alias}`,{
                        pauseOnFocusLoss: false,
                        theme: "colored",
                        toastId: `exito-env${element.keyId}`
                    })                      
                });
                
                setModalAbierto(false)                        
            }       
        }    
        apiFDataHeader(bodyFormData, url,respuesta,ErrorGeneral,setEspera,history,source,headers,setProgresoEnvio);
    }  

    function reemplazo(cadena) {
        let nvo=cadena
        const exPermitidaNombrePubli = new RegExp('[$@#&%+()/:;!?,<>=]')
        const prb = "[$@#&+()/%:;!?,<>=]"
        for (let index = 0; index < prb.length; index++) 
        {
            const element = prb[index];
            while (nvo.indexOf(element)!== -1) 
            {   nvo = nvo.replace(exPermitidaNombrePubli, "")                                         
            }                    
        }
        while (nvo.indexOf(" ")!== -1) 
        {   nvo = nvo.replace(" ", "")                                         
        }   
        return(nvo)
    }
    
    function ErrorGeneral(error,historial) {
        console.log(error);   
        toast.error(`Ha ocurrido un error, vuelve a intentarlo mas tarde.`,{
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `mensaj-err1`
        })           
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        { console.log('datos incorrectos' );  
        }
        else
        {
          if ( ( error && error.response && error.response.data && error.response.data?.error?.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data?.error?.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                            
            historial("/");                              
          }
          else{
            if ( ( error && error.response && error.response.data && error.response.data.mensaje==="No se agrego el Token en los Headers de la petición." )) 
            {
              console.log('loguearse de nuevo');
              localStorage.clear();                            
              historial("/");                              
            }
          } 
        }      
    }

      
    function onChangeArchivos2(event){
        setArrayPreview([])
        setArraySubidos([])        
        const regex = /([a-zA-Z0-9\s_\.\-\(\):])+(.mp4|.flv|.avi)$/
        let arrArch2 = [], auxId=0, arrPrev=[], valido = true;
        let limite=event.target.files.length <= 3 ? event.target.files.length :3 
        for (let i =0;i <limite ;i++){
            let img = event.target.files[i]
            let auxCode = uuidv4();
            let nom ="";                
            if(img.name.length>=25)
            {   nom =  img.name.substring(0,24)+"..."+img.name.substring(img.name.lastIndexOf("."),img.name.length);
            }
            else
            {   nom = img.name;
            }
            if(img && img.name.toLowerCase().match(regex))
            {   let auxNombre = auxCode + img.name.slice(img.name.lastIndexOf("."), img.name.length);
                //let auxNombre =reemplazo(img.name);                                       
                let myNewFile = new File([img], auxNombre, { type: img.type });   
                auxId=parseInt(event.target.name.substring(4,event.target.name.length))
                arrArch2={archivo:myNewFile, Id:auxId, alias:img.name}
                arrPrev.push(URL.createObjectURL(img))               
            }
            else
            {   
                let mensaje=`Formato de archivo incorrecto en ${nom} seleccione un tipo mp4, flv, avi.`
                toast.warning(mensaje,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel`
                  }); 
                valido=false;
                //setArraySubidos([])
                break;
            }
        }
        if(valido)
        {   let filtro= arraySubidos.findIndex((tema)=>tema.Id === auxId)            
            let mod=arraySubidos
            if (filtro=== -1) 
            {  
                if (arrArch2.length!==0) {
                    mod.push(arrArch2)      
                }             
            }
            else
            {   mod.splice(filtro,1,arrArch2)                 
            }                        
            setArraySubidos(mod)
            setArrayPreview(arrPrev)
        }
    }    
  
    const onChange=(e)=>{    
        setDescripcion(e.target.value)     
    }

    function borrarArchivo(auxId){
        setArrayPreview([])      
        let filtro= arraySubidos.findIndex((tema)=>tema.Id === auxId)            
        let mod=arraySubidos   
        if (filtro!== -1) 
        {   
            mod.splice(filtro,1)                 
        }
        setArraySubidos(mod)
    }

    const guardarDatos = () => 
    {   console.log(arraySubidos);
        if (arraySubidos.length!==0) 
        {  
         llenarListEvid();    
        }   
        else{
            let mensaje=`Debe seleccionar un archivo para subir`
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            }); 
        }    
    };
    const accept="video/mp4,video/flv,video/avi"    

    const reproductor=(archivo,ancho)=>{
        return(
        <audio controls autoplay id="miAudio" style={{height:1.5+'rem',width:ancho}} >
            {listTipoAudio.map((formato)=>(
                <source src={archivo} type={ formato.tipo} />     
            ))}                                
        </audio>
        )
    }

  return (
    <FormDialog
        titulo={`Subir Videos a ${registro.Titulo}`} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        tamanio="lg" btnDeshabilitado={false} cargando={espera}
        setCargando={setEspera} btnTitulo={"Enviar"}
    > 
    <form  noValidate autoComplete="off" fullWidth>       
    <Box  display="flex" flexDirection="column" sx={{width:100+'%'}}  className={classes.root}>                              
        {listTemaMod.length !== 0  ? 
            listTemaMod.map((tema)=>{
              return<Card sx={{marginBottom:.5+'rem'}}> 
                <Grid sx={{ display: 'flex', flexDirection: 'row', marginTop:'.3em',marginRight:'.7em',alignContent:"center",width:100+'%'}}>               
                    <Grid  container xs={2} alignItems="center"  >
                        <input type="file" name={`file${tema.Id}`} onChange={onChangeArchivos2} 
                            hidden id={`cargarArchivo${tema.Id}`} accept={accept}  />
                        <label htmlFor={`cargarArchivo${tema.Id}`} >   
                            <Tooltip title="Cargar archivo">
                            {tema.LinkVideo.length!==0? 
                                <CloudUploadIcon color="primary"  sx={{ fontSize: 40 ,margin:.5+'rem'}}/>       
                            :<CloudUploadIcon color="" sx={{ fontSize: 40 ,margin:.5+'rem'}}/>
                                }                                
                            </Tooltip>               
                        
                        </label>
                    </Grid>
                    <Grid container   alignItems="center" >
                        <section> 
                            <Typography  id={`descripcion-campo${tema.Pista}`}                    
                                sx={{width:"17rem", marginLeft:.5+'rem'}}                         
                            >
                                {`${tema.Pista} - ${tema.Titulo}`}
                            </Typography>                
                            {tema.LinkVideo.length!==0? 
                                <span>
                                    <Box sx={{ display: {   xs: 'block' , sm: 'none'} }} >
                                        <Player playsInline poster="" src={tema.LinkVideo}  />
                                    </Box>
                                    <Box  sx={{ display: {   xs: 'none' , sm: 'block'} }} >
                                        <Player playsInline poster="" src={tema.LinkVideo}  />                
                                    </Box>        
                                </span>
                            :null}   
                        </section>                        
                    </Grid>
                    {arraySubidos.length!==0 ? 
                        arraySubidos.map((archivo)=>{
                            if (archivo.Id===tema.Id) 
                            {return(
                                <Grid xs={6}>
                                <Box display={"flex"} flexDirection="row" sx={{  alignContent:"center"}}>                   
                                    <Typography color={"primary"}> {archivo.alias} </Typography>  
                                    <Tooltip title="Descartar archivo">
                                        <HighlightOffIcon size="small" color={"primary"}                                                 
                                            sx={{marginLeft:.5+'rem',paddingBottom:.1+'rem',paddingTop:.1+'rem'}}  
                                            onClick={()=>borrarArchivo(tema.Id)} 
                                        />   
                                    </Tooltip>
                                </Box>
                                </Grid>
                            )        
                            }                        
                        })
                    : null
                    }
                </Grid>
            </Card> 
         
        }):null}            
        {progresoEnvio>0 ? (
            <Progress progress={progresoEnvio}/>)
        :null}         
    </Box>
    </form>
    </FormDialog>
  )
}

export default ModalListVideo