import React, { useState } from 'react'
import chroma from 'chroma-js';

//import { ColourOption, colourOptions } from '../data';
import Select, { StylesConfig } from 'react-select';

const colourStyles= {
    control: (styles) => ({ ...styles, backgroundColor: 'white' , width:30+'rem',}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
       
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
      
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

const SelectGen = ({colourOptions}) => {
    const [valor,setValor]=useState(null)
    const onChange=(newValue)=>{
        console.log(newValue);
        setValor(newValue)
    }
  return (
   <Select
    value={valor}
    closeMenuOnSelect={false}
    //defaultValue={[colourOptions[0], colourOptions[1]]}
    isMulti
    options={colourOptions}
    styles={colourStyles}
    onChange={onChange}
    style={{}}
  />
  )
}

export default SelectGen