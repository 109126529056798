import React,{useState,useEffect} from 'react'
import {Box,Card,CardContent,IconButton,LinearProgress,TextField,
  FormControl,InputLabel,Select,MenuItem, Grid,Tooltip 
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import {Search as SearchIcon} from '@mui/icons-material/';
import axios from 'axios';
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../../funciones/ClienteAxios';
import { printConsole } from '../../funciones/ConsolaLog';
import TablaAlbum from './TablaAlbum';
import moment from 'moment';
import TablaArchivos from './TablaArchivos';
const useStyles = makeStyles()((theme) => {
  return {
    root: {
        minWidth: 275,
    },    
    title: {
        fontSize: 28,
    },
    title2: {
        fontSize: 18,
    },
    subTitle2: {
        fontSize: 12,
    },
    pos: {
    marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(1),       
    },
    altura2: {
      
      //width:'100%',
      marginTop:0,
      paddingTop:0,
      // width:275
    },  
  };
});

const Album = () => {

  const { classes } = useStyles();
  const source = axios.CancelToken.source();
  const history = useNavigate();
  const hoy=moment(new Date()).format('YYYY-MM-DD')
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const usuId =localStorage.getItem('UsuId') ; 
  const [filtro, setFiltro] =  useState({registro:"",tipo:1,FIni:hoy, 
    FFin:hoy});
  const [espera, setEspera] = useState(false)
  const [esperaArch, setEsperaArch] = useState(false)
  const [listTipo,setListTipo]= useState([]) 
  const [datos, setDatos] = useState([])
  const [contrato, setContrato] = useState([])
  const [listArchivos, setListArchivos] = useState([])
  const [listArchTema, setListArchTema] = useState([])
  const {registro,tipo,FIni,FFin}=filtro;
  useEffect(() => {
    const llenarListTipo = () => {      
      let data = qs.stringify({
        usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "contrato-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListTipo(aux);                
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };
    if (registro.length!==0) {
      llenaListBusq(1)  
    }
    
    llenarListTipo()
    return () => {
      source.cancel();
    }
  }, [ ])

  useEffect(() => {
   
    if (contrato.length!==0) 
    {
      llenarListFile(1)  
      llenaListFileTem()
    }
  
    return () => {
      source.cancel();
    }
  }, [ contrato])

  const llenarListFile = (tipo) => {      
    let data = qs.stringify({
      Usuario:usuId,
      Album:contrato.Id,
      Tipo:"AUDIO",
      idDispositivo:auxDispV
    });
    let url = "album-tema-list";
    function respuesta(aux) {
      if (aux[0].Id !== -1) 
      { setListArchivos(aux);                  
      }else{
        setListArchivos([]);                  
      }
    }
    if (tipo===1) 
    {
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEsperaArch, history, source);  
    }else{
      llamadaApiToken(data, url, respuesta, ErrorGeneral,  history, source);
    }
    
  }; 

  const llenaListFileTem = () => {      
    let data = qs.stringify({
      Usuario:usuId,
      Album:contrato.Id,      
      idDispositivo:auxDispV
    });
    let url = "album-tema-file-list";
    function respuesta(aux) {
      if (aux[0].Id !== -1) 
      { setListArchTema(aux);                  
      }else{
        setListArchTema([]);                  
      }
    }
          
    llamadaApiToken(data, url, respuesta, ErrorGeneral,  history, source);
  }; 

  const llenaListBusq = (tipo) => {
    setDatos([])
    setListArchTema([])
    setListArchivos([])
    let data = qs.stringify({
        Usuario:usuId,     
        Cadena:registro,            
        idDispositivo: auxDispV
    });
    let url = "album-list-filtro";

    function respuesta(auxiliar) 
    { 
      if (auxiliar[0].Id !== -1) 
      {                 
        setDatos(auxiliar)
      }
      else
      {
        setDatos([])
      }
    }
    if (tipo===1) {
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);  
    }else{
      llamadaApiToken(data, url, respuesta, ErrorGeneral,  history, source);
    }
    
    
  };

  const onChange = e =>
  { let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
      !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
      ||e.target.value===' '||e.target.value==='') 
    {
      setFiltro({
        ...filtro,
        [e.target.name] : e.target.value
      })
    }        
  };

   const Buscar=()=>{  
    setContrato([])  
    llenaListBusq(1)    
   }

   const onChangeDate=(e)=>{
    if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined 
      
    ) {
      if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
      {        
        setFiltro({...filtro,[e.target.name] : e.target.value})                   
      }      
    }
    
  }

  const selectTipo= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
  <InputLabel id="demo-select-small">Tipo</InputLabel>
  <Select
    labelId="demo-select-small" id="demo-select-small" name="tipo"
    value={tipo} label="Tipo" onChange={onChange}
  >      
    {listTipo.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Alias}</MenuItem>)}
  </Select>
</FormControl>
 const llamadaxEnter=(event)=>{
  if (event.keyCode === 13 && registro.length>=1) 
  {   Buscar( )            
  }
}

  return (
  <Box style={{ padding:0,margin:0}} >
    <Card className={classes.altura2}  >
      <CardContent >
        <Box display="flex" flexDirection="row" >                                                                      
          <Box pr={1} >
            <Tooltip title="Buscar">
            <IconButton color="primary"  aria-label="add circle" size="small"
              component="span"   onClick={Buscar}
            >
              <SearchIcon/>
            </IconButton>         
            </Tooltip>                                                                                                                     
          </Box>                               
          <Box>
            <TextField size="small"
              id="outlined-basic" label="Nombre" variant="outlined"              
              style={{ width: 20 + "rem", marginBottom: 8, height: 1 + "rem"}}
              name="registro" value={registro} onChange={onChange} 
              onKeyUp={(event)=>  llamadaxEnter(event)}
            />
          </Box>
                                                   
        </Box>                                                                                                                  
      </CardContent>                                
    </Card>
    <Grid direction="column" container  sx={{marginTop:.2+'rem'}} >
      <Grid sx={{ width:'95%'}} justifyContent="center">
        {  espera && datos.length ===0 ?
          <Box  sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
        : <TablaAlbum datos={datos} setContrato={setContrato} Buscar={Buscar} 
            listArchTema={listArchivos} llenarListFile={llenarListFile} 
            llenaListBusq={llenaListBusq} listVideo={listArchTema}
            llenaListFileTem={llenaListFileTem}
          />  }
      </Grid>
      <Grid sx={{width:'95%',marginTop:1+'rem' , }}>
        { esperaArch   ? <Box sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
        : contrato.length!==0?
          <TablaArchivos datos={listArchivos} contrato={contrato}  llenarListFile={llenarListFile} 
            llenaListBusq={llenaListBusq}
          />            
          :null
        }
      </Grid>
    </Grid>
  </Box> 
  )
}

export default Album