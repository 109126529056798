import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {
    Avatar,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";


export default function DaduvaTable({initialPage, elementsPerPage, isDense, columns, rows, rowActions, colActions}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        initialPage !== undefined ? setPage(initialPage) : setPage(0);
        elementsPerPage !== undefined ? setRowsPerPage(elementsPerPage) : setRowsPerPage(25);
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (<>
        <TableContainer component={Paper} sx={{maxHeight: '80vh'}}>
            <Table sx={{width: '100%'}} aria-label="interpretes table">
                <TableHead sx={{
                    backgroundColor: (theme) => theme.palette.secondary.light,
                }}>
                    <TableRow>
                        {rowActions !== undefined ? <TableCell sx={{
                            padding: isDense !== undefined ? '0.2rem' : null,
                            color: (theme) => theme.palette.secondary.contrastText
                        }}>
                            {rowActions.map((action) => (
                                <Tooltip key={action.label} title={action.label} arrow placement="top">
                                    <IconButton onClick={action.function} size={isDense !== undefined ? 'small' : 'medium'} color={'inherit'}>
                                        {action.icon}
                                    </IconButton>
                                </Tooltip>))}
                        </TableCell> : colActions !== undefined ? <TableCell align={'center'}>
                        </TableCell> : null}
                        {columns.map((column) => (<TableCell sx={{padding: isDense !== undefined ? '0.2rem' : null, color: (theme) => theme.palette.secondary.contrastText}}
                                                             key={column.id}
                                                             align={column.align}
                                                             style={{minWidth: column.minWidth}}
                        >
                            {column.label}
                        </TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length === 0 ? <>
                        <TableRow hover sx={{
                            '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                                backgroundColor: (theme) => theme.palette.secondary.light,
                                color: (theme) => theme.palette.secondary.contrastText,
                            }, '&:last-child td, &:last-child th': {border: isDense !== undefined ? 0 : null}
                        }}>
                            <TableCell colSpan={"100%"} align={'center'}
                                       sx={{padding: isDense !== undefined ? '0.2rem' : null}}>
                                <h4>No existen datos </h4>
                            </TableCell>
                        </TableRow>
                    </> : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (<TableRow hover sx={{
                            '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                                backgroundColor: (theme) => theme.palette.secondary.light,
                                color: (theme) => theme.palette.secondary.contrastText,
                            }, '&:last-child td, &:last-child th': {border: isDense !== undefined ? 0 : null}
                        }} role="checkbox" tabIndex={-1} key={index}>
                            {colActions !== undefined ? <TableCell key={index} sx={{
                                padding: isDense !== undefined ? '0.2rem' : null
                            }}>
                                {colActions.map((action) => (
                                    <Tooltip key={action.label} title={action.label} arrow placement="top">
                                        <IconButton onClick={ action.function(row.Id)}
                                                    size={isDense !== undefined ? 'small' : 'medium'}>
                                            {action.icon}
                                        </IconButton>
                                    </Tooltip>))}
                            </TableCell> : null}
                            {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id.includes('Img') || column.id.includes('Image')) {
                                    return (<TableCell key={column.id} align={column.align}
                                                       sx={{padding: isDense !== undefined ? '0.2rem' : null}}>
                                        <Avatar alt={value} src={value}/></TableCell>);
                                } else {
                                    return (<TableCell key={column.id} align={column.align}
                                                       sx={{padding: isDense !== undefined ? '0.2rem' : null}}>
                                        {value === '' ? 'Sin ' + column.id : value}</TableCell>);
                                }
                            })}
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        {
            initialPage !== undefined && elementsPerPage !== undefined ? <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> : null
        }
    </>)
        ;
}