import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { preventClose } from "../Generales/Tools";
import CloseIcon from "@mui/icons-material/Close";
import { green } from "@mui/material/colors";

export default function FormDialog({
  titulo,
  children,
  modalAbierto,
  setModalAbierto,
  guardarDatos,
  tamanio,
  btnDeshabilitado,
  cargando,
  setCargando,
  bgTitle,
  btnTitulo,
}) {
  const handleClose = (event) => {
    event.preventDefault();
    setModalAbierto(false);
    setCargando(false);
  };

  return (
    <Dialog
      open={modalAbierto}
      maxWidth={tamanio}
      onClose={(event, reason) => preventClose(event, reason, setModalAbierto)}
    >
      <DialogTitle
        sx={
          bgTitle
            ? {
                m: 0,
                p: 2,
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "white",
              }
            : {
                m: 0,
                p: 2,
                mr: 5,
                color: (theme) => theme.palette.primary.main,
              }
        }
      >
        {titulo}
        <Tooltip title={"Cerrar"}>
          <IconButton disabled={cargando}
            aria-label="close" onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
      <DialogActions>
        {/*<Button onClick={handleClose} disabled={cargando} color="error">Cancelar</Button>*/}
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="outlined"
            onClick={guardarDatos}
            disabled={btnDeshabilitado ? true : cargando}
          >
            {btnTitulo ? btnTitulo : "Guardar"}
          </Button>
          {cargando && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
