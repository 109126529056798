import React,{useState,useEffect} from 'react'
import {Box,LinearProgress,Grid,Card,Typography, Button} from '@mui/material';
import { makeStyles } from 'tss-react/mui' 
import axios from 'axios';
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../../funciones/ClienteAxios'; 
import TablaGenero from './TablaGenero';
import NvoGenero from './NvoGenero'
const Genero = () => { 
  const source = axios.CancelToken.source();
  const history = useNavigate();  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const usuId =localStorage.getItem('UsuId') ;  
  const [espera, setEspera] = useState(false) 
  const [datos, setDatos] = useState([])
  const [contrato, setContrato] = useState([]) 
  const [modalNvoGenero, setModalNvoGenero] = useState(false) 
   
  useEffect(() => {
    llenaListGen(1)  
    return () => {
      source.cancel();
    }
  }, [ ])  

  const llenaListGen = (tipo) => {
    setDatos([])     
    let data = qs.stringify({
        Usuario:usuId,         
        idDispositivo: auxDispV
    });
    let url = "genero-list";

    function respuesta(auxiliar) 
    { 
      if (auxiliar[0].Id !== -1) 
      {                 
        setDatos(auxiliar)
      }
      else
      {
        setDatos([])
      }
    }

    if (tipo===1) {
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);  
    }else{
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    }
    
  };
 
   const Buscar=()=>{    
    llenaListGen(2)    
   }
 
  const modNvoGen = modalNvoGenero ?<NvoGenero modalAbierto={modalNvoGenero} 
                                      setModalAbierto={setModalNvoGenero} datos={datos}/> : null
  return (

  <Grid sx={{marginRight:.5+'rem',marginTop:1+'rem' }}>
    {  espera && datos.length ===0 ?
      <Box  sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
    : 
     datos.length!==0?
      <Grid container direction={"row"}>    
      {datos.map((genero)=>(
        <Grid item>
          <Card sx={{borderRadius: "7px",background:genero.Color,width:10+'rem'}}>    
          <Typography variant="body1"  
            sx={{marginLeft:.5+'rem',marginRight:.5+'rem',
              marginTop:.2+'rem',marginBottom:.2+'rem'}} 
          >
            {genero.Alias}
          </Typography>    
        </Card>
        </Grid>))}
        </Grid>
      : null 
   }
   {datos.length !==0 ? <Box><Button onClick={()=>setModalNvoGenero(true)} >Modal</Button></Box>:null}
   {modNvoGen}
  </Grid>          
 
  )
}

export default Genero