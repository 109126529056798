import React from 'react'
import useAnalyser from "../hooks/useAnalyser2";
import {Box } from '@mui/material';
const Reproductor = ({link,ext}) => {
    
  const [player, bars] = useAnalyser(link,ext  );
  return (
  <div style={{ fontFamily: "sans-serif", textAlign: "center",marginBottom:.5+'rem'}}>
    {player}
        
     
      <div style={{  display: "flex",backgroundColor:"#e0e0e0",borderRadius:.4+'rem' }}>
        {bars}
      </div>
    
  </div>  
  )
}

export default Reproductor