import {useContext, useEffect} from "react";
import {AuthContext} from "./AuthContext";
import {GeneralContext} from "../Generales/GeneralContext";
import {Outlet, useNavigate,useLocation} from "react-router-dom";
//import {authUser} from "../funciones/AuthUser";
import moment from 'moment';
//import { printConsole } from "../funciones/ConsolaLog";
import { rutas } from "./rutasPermisos";

const ProtectedRoute = ({children}) => {
    const navigate = useNavigate()
    const  location = useLocation();
    const {guardarUsuario} = useContext(AuthContext);
    const {genelares,setGenerales} = useContext(GeneralContext);
    
    useEffect(() => {
        const usu = localStorage.getItem('UsuarioNom');
        const nvl = localStorage.getItem('Tipo');
        const fecha = localStorage.getItem('fch22');
        const usrOpera = localStorage.getItem("UserOperacion");
        let bandera=false        
        if (fecha) 
        {   let fechaMod=moment(fecha).add(24,'h').format("YYYY-MM-DDTHH:mm")            
            const hoy=moment().format("YYYY-MM-DDTHH:mm")
            if (fechaMod>hoy) 
            {   bandera=true
            }             
        } 
        if (  usu !== null&& usrOpera  && bandera ) {
            const ruta=location.pathname
            let filtrado=rutas.filter((filtro)=>"/"+filtro.ruta===ruta)
            const titulo=filtrado[0].length !==0 ? filtrado[0].titulo :""
            guardarUsuario({Nombre: usu, nivel: nvl ? parseInt(nvl):null,opera:usrOpera});
            setGenerales({...genelares,titulo:titulo})
        } else {
            localStorage.clear();
            guardarUsuario([])
            setGenerales([])
            navigate("/");
        }
    }, [location]); 

    return children ? children : <Outlet/>
};

export default ProtectedRoute;
