export const rutas=[
    {ruta:'busqueda' ,titulo:'Busqueda',opera:1,tipo:[1,2,3,4,5] },
    {ruta:'interprete' ,titulo:'Interprete',opera:2,tipo:[1,2]},
    {ruta:'contrato' ,titulo:'Contrato',opera:3,tipo:[1,2,3]},
    {ruta:'sello' ,titulo:'Sello',opera:4,tipo:[1,2]},
    {ruta:'album' ,titulo:'Álbum',opera:5,tipo:[1,2,4,5]},
   // {ruta:'genero' ,titulo:'Genero',opera:6,tipo:[1,2,4,5]},
    {ruta:'usuario' ,titulo:'Usuario',opera:66,tipo:[1]},
]



/* 1 => Administrador
2 => Operador Gral
3 => Operador Contratos
4 => Operador Audios
5 => Operador Videos */