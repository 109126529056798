import React,{useState,useEffect} from 'react';
import { makeStyles } from 'tss-react/mui';
import {Box , Button,Card,Typography, TextField, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FormDialog from '../../Layout/FormDialog'
import {toast} from 'react-toastify';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../../funciones/ClienteAxios';
import { printConsole } from '../../funciones/ConsolaLog';
import RegistroTema from './RegistroTema';
import {celda} from '../../Generales/TablaGeneral';
import {AddCircleOutline} from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      },         
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const ModalListTema = ({modalAbierto,setModalAbierto,titulo,registro,listTemas}) => {
    const history = useNavigate();
    const classes = useStyles();
    const source = axios.CancelToken.source();      
    const idusu= localStorage.getItem('UsuId');    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    const [espera,setEspera]=useState(false);
    const general={ pista:1, estatus:1, Id:1,
        fAlta:"2022-12-01T00:00:00", fPublica:"2022-12-15", 
        isrc:"59121515", ft:"85411", titulo:"Ayreon", 
        autor:"Marcela Bovio", editora:"DDV",  duracion:"04:33"
    }
    const [datos,setDatos]=useState([
        { pista:1, estatus:1, Id:1,
            fAlta:"2022-12-01T00:00:00", fPublica:"2022-12-15", 
            isrc:"59121515", ft:"85411", titulo:"Ayreon", 
            autor:"Marcela Bovio", editora:"DDV",  duracion:"04:33"
        }
    ]);
    const [arraySubidos,setArraySubidos] = useState([])
    const [descripcion, setDescripcion] = useState("")
    const [contador,setContador] = useState(1)
    const [bandera,setBandera] = useState(0)
    useEffect(()=>{    
        let nvo=[]        
        listTemas.map((tema)=>{
            nvo.push({
                pista:tema.Pista, estatus:1, Id:1,
                fAlta:tema.FAlta.substring(0,19), 
                fPublica:tema.FPublica.substring(0,10), 
                isrc:tema.ISRC, ft:tema.Ft, titulo:tema.Titulo, 
                autor:tema.Autor, editora:tema.Editora,  duracion:tema.Duracion
            })
             
        }) 
        setDatos(nvo)
        return ()=> {
            source.cancel();
        }     
        
    },[]);

    
    const addList=()=>{
        const modificado={...general,pista:contador+1,Id:contador+1}
        datos.push(modificado)                
        setContador(contador+1)
    }
  
    const onChange=(e)=>{    
        setDescripcion(e.target.value)     
    }

    const llamadaApi = (auxList) => {                   
    
        let data = qs.stringify({
            Album:registro.Id,
            Usuario:idusu,
            Temas:JSON.stringify(auxList),
            idDispositivo: auxDispV,
        });
        
        let url = "album-tema-new-edit";
        function respuesta(auxiliar){            
          if ( auxiliar) 
          {                 
           // Buscar()
            let mensaje="Se creo correctamente la lista"        
            toast.success(`${mensaje}`,{
              pauseOnFocusLoss: false,
              theme: "colored", toastId: `exito-env`
            })  
    
            setModalAbierto(false)    
          }     
            
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)        
      };  

      
    const guardarDatos = () => 
    {   setBandera(bandera+1)
        if (arraySubidos.length!==0) 
        {  
            //llenarListEvid();    
        }   
        else{
            let mensaje=`Debe seleccionar un archivo para subir`
            toast.warning(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            }); 
        }    
    };

 
    const tablaCompleta = () => {

        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {celda("center", {}, "Estatus" , 0, "") }
                    {celda("center", {}, "Pista" , 1, "") }
                    {celda("center", {},"ISRC", 2, "")}                                           
                    {celda("center", {}, "FT",3, "")}   
                    {celda("center", {}, "Fecha Publicación",4, "")}     
                    {celda("center", {}, "Titulo",5, "")}     
                    {celda("center", {}, "Autor", 6, "") }     
                    {celda("center", {}, "Editora", 7, "")}
                    {celda("center", {}, "Duración",8, "")}                                                                                                      
                </TableRow>
            </TableHead>
            <TableBody>                                                                 
                {datos.length !== 0  ? 
                    (datos.map((row,index) => (                        
                        <RegistroTema key={index} row={row} bandera={bandera}
                            datos={datos} setDatos={setDatos} />
                    ))) 
                : 
                <TableRow className={classes.tableRow} >            
                    {celda("center", {}, "", 1, classes.tableCell)}
                    {celda("center", {}, "", 2, classes.tableCell)}
                    {celda("center", {}, "", 3, classes.tableCell)}
                    {celda("center", {}, "Sin", 4, classes.tableCell)}
                    {celda("center", {}, "", 5, classes.tableCell)}
                    {celda("center", {}, "", 6, classes.tableCell)}
                    {celda("center", {}, "Registros", 7, classes.tableCell)}
                    {celda("center", {}, "", 8, classes.tableCell)}                    
                </TableRow>       
                }
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    
    const btnMas = <Button variant="outlined" onClick={addList}>Agregar Tema</Button>
  return (
    <FormDialog
        titulo={`${titulo}`} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        tamanio="xl" btnDeshabilitado={false} cargando={espera}
        setCargando={setEspera} btnTitulo={"Enviar"}
    > 
    <form  noValidate autoComplete="off" fullWidth>   
        {btnMas}    
      {tablaCompleta()}
    </form>
    </FormDialog>
  )
}

export default ModalListTema