import React,{createContext,useState} from 'react';

export const AuthContext = createContext();

const AuthProvider = (props) => {
    const [usuario,guardarUsuario]=useState([])
    return (
        <AuthContext.Provider
            value={{
                usuario,guardarUsuario
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;