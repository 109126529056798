import React,{useState,useEffect} from 'react'
import { Box, IconButton, Table, TableBody, Tooltip,
    TableContainer, TableHead, TableRow, Typography,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import moment from 'moment';
import {celda} from '../../Generales/TablaGeneral';
import { printConsole } from '../../funciones/ConsolaLog';
import NuevoContrato from '../Contrato/NuevoContrato';
import {AddCircleOutline} from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ModalArchivo from './ModalArchivo';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 420,
            width: '22rem',
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaArchivos = ({datos,contrato,llenarListFile}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [modalNvoContrato,setModalNvoContrato]= useState(false);
    useEffect(() => {
        printConsole(datos)
    
    
    }, [ ])
 
    function Row(props) {
        const {row} = props;       
        let nomLink=row.Link.length!==0 ? row.Link.substring(row.Link.lastIndexOf('/')+1,row.Link.length):"" 
        let modespacio=(row.Size/1000000).toString()
        let espacio=modespacio.length!==0 ? modespacio.substring(0,modespacio.lastIndexOf('.')+3):"" 
        return (           
        <TableRow
            onClick={() => { setSelectedID(row) }}
            selected={selectedID?.Id === row.Id} className={classes.tableRow}
            classes={{hover: classes.hover, selected: classes.selected}}
        >
             {celda("center", {width: 45+'px'}, 
                row.Link.length !== 0 ?
                <Tooltip title={`Descargar ${nomLink}`}>
                <a href={`javascript:window.open('${row.Link}');`}> ​​​​​​​​ 
                    <IconButton size="small"  >
                        <ArrowCircleDownIcon/>
                    </IconButton>
                </a>
                </Tooltip>
                :null
            , 4, classes.tableCell)}   
            {celda("center", {fontSize:12+'px'}, `${nomLink} `, 10, classes.tableCell)}      
            {celda("center", {width: 45+'px',fontSize:12+'px'}, 
                row.FReg=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.FReg).format('DD/MM/YYYY')
            , 4, classes.tableCell)}  
            {celda("center", {}, `${espacio} mb`, 7, classes.tableCell)}      
             {celda("left", {fontSize:12+'px'}, row.Tipo, 4, classes.tableCell)}                                   
            {celda("left", {fontSize:12+'px'}, row.Descr, 1, classes.tableCell)}            
        </TableRow>                          
        );
    }

const tablaCompleta = () => {

    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                {celda("left", {},  
                    <Tooltip title={`Agregar Archivo al Contrato ${contrato.Id}`} >
                        <IconButton onClick={()=>{setModalNvoContrato(true)}} size="small" >
                            <AddCircleOutline />
                        </IconButton>
                    </Tooltip>
                , 1, "")}                 
                {celda("center", {}, "Nombre",2, "")}  
                {celda("center", {}, "Envio",84, "")}     
                   
                {celda("center", {}, "Tamaño",4, "")}    
                {celda("center", {}, "Tipo",4, "")}                    
                {celda("center", {}, "Descripción", 5, "")}                                                                                               
            </TableRow>
        </TableHead>
        <TableBody>                                                                 
        {datos.length !== 0  ? 
            (datos.map((row) => (
                <Row key={row.Id} row={row}/>
            ))) 
        : 
        <TableRow className={classes.tableRow} >            
            {celda("center", {}, "", 6, classes.tableCell)}   
            {celda("center", {}, "", 1, classes.tableCell)}    
            {celda("center", {}, "Sin", 3, classes.tableCell)}
            {celda("center", {}, "", 4, classes.tableCell)}
            {celda("center", {}, "Registros", 5, classes.tableCell)}
            {celda("center", {}, "", 4, classes.tableCell)}
        </TableRow>       
        }
        </TableBody>
    </Table>
    </TableContainer>
    )
    }

    const modNvoContrato= modalNvoContrato ? <ModalArchivo modalAbierto={modalNvoContrato} llenarListFile={llenarListFile}
                                                setModalAbierto={setModalNvoContrato} registro={contrato} /> :null
  return (
    <Box>
        {modNvoContrato}
        <div><Typography color="primary" sx={{fontSize:18,fontWeight:500}}>{`Archivos del Contrato y Anexos ${contrato.Id}`}</Typography></div>
        {tablaCompleta()}
   </Box>
  )
}

export default TablaArchivos