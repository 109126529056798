export function findNumber(num) {
    let res
    let str = localStorage.getItem("UserOperacion")+"|";

    if (str !== null) {
        res = str.indexOf(num) 
        //console.log(res);
        if (res === -1) {
            return false
        } else {
            return true
        }
    }


}

export function findNumberCad(num,cadena) {
    let res
    let str = cadena+"|";

    if (str !== null) {
        res = str.indexOf(num) 
        //console.log(res);
        if (res === -1) {
            return false
        } else {
            return true
        }
    }


}

export function findTipoCad(num,arreglo) {        
    const filtrado = arreglo.filter(tipo=>tipo===num)        
    if (filtrado.length===0) 
    {
        return false        
    }     
    else 
    {
        return true
    }   
}