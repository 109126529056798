import {Box, } from "@mui/material";
import FormDialogInfo from '../../Layout/FormDialogInfo'
//import {useState} from "react";
const ImgAlbum = ({modalAbierto, setModalAbierto, registro}) => {
  return (
  < FormDialogInfo tamanio={'md'} bgTitle={false}
    titulo={ `${registro.Titulo}`}
    modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto}        
  >
    <Box style={{width:25+'rem'}} >
      <img src={registro.Img} style={{width:100+'%',borderRadius:.5+'rem'}}  />
    </Box>
  </FormDialogInfo>
  )
}

export default ImgAlbum