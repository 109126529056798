import React,{useState,useEffect} from 'react'
import {Box,LinearProgress,Grid,} from '@mui/material';
import { makeStyles } from 'tss-react/mui' 
import axios from 'axios';
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from '../../funciones/ClienteAxios'; 
import TablaSello from './TablaSello';
  
const Sello = () => { 
  const source = axios.CancelToken.source();
  const history = useNavigate();  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const usuId =localStorage.getItem('UsuId') ;  
  const [espera, setEspera] = useState(false) 
  const [datos, setDatos] = useState([])
  const [contrato, setContrato] = useState([]) 
 
  useEffect(() => {
    llenaListSello(1)  
    return () => {
      source.cancel();
    }
  }, [ ])  

  const llenaListSello = (tipo) => {
    setDatos([])     
    let data = qs.stringify({
        Usuario:usuId,         
        idDispositivo: auxDispV
    });
    let url = "contrato-sello";

    function respuesta(auxiliar) 
    { 
      if (auxiliar[0].Id !== -1) 
      {                 
        setDatos(auxiliar)
      }
      else
      {
        setDatos([])
      }
    }

    if (tipo===1) {
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);  
    }else{
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    }
    
  };
 
   const Buscar=()=>{    
    llenaListSello(2)    
   }
 
  return (

  <Grid sx={{marginRight:.5+'rem' }}>
    {  espera && datos.length ===0 ?
      <Box  sx={{ width: '100%' ,marginTop:2+'rem'}}><LinearProgress/></Box>
    : <TablaSello datos={datos} setContrato={setContrato} Buscar={Buscar} />  }
  </Grid>          
 
  )
}

export default Sello