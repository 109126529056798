import React, { useState ,useEffect} from 'react'
import { Checkbox, TableRow,    TextField,FormControlLabel
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import {celda} from '../../Generales/TablaGeneral';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '50rem',
        },
        container: {
            maxHeight: 420,
            width: '50rem',
        },
        tableRow: {
            padding:10+'px',
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            },
            
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });
const RegistroTema = ({row,datos,activo,bandera}) => {
    const classes = useStyles();
    const[tema,setTema]=useState({ pista:1, estatus:1, 
    fAlta:"2022-12-01T00:00:00", fPublica:"2022-12-15", 
    isrc:"", ft:"", titulo:"", 
    autor:"", editora:"",  duracion:"04:33"})
    const{pista, estatus, fAlta, fPublica, isrc, ft, titulo,autor, editora ,  duracion,}=tema
    const[valido,setValido] = useState(true)
    const [value, setValue] = useState(moment(`2022-12-01T00:${row.duracion}`));
    useEffect(() => {
      
        setTema({ pista:row.pista, estatus:row.estatus, 
            fAlta:row.fAlta, fPublica:row.fPublica, 
            isrc:row.isrc, ft:row.ft, titulo:row.titulo, 
            autor:row.autor, editora:row.editora,  duracion:row.duracion})
        //setValido(row.estatus)
      
    }, [])

    useEffect(() => {        
        if (bandera>=1) 
        {
            const pos=datos.findIndex((filtro)=>filtro.Id ===row.Id)            
            datos.splice(pos,1,{...row,pista: pista, estatus: estatus,  
                fAlta: fAlta, fPublica: fPublica, 
                isrc: isrc, ft: ft, titulo: titulo, 
                autor: autor, editora: editora,  duracion: moment(value).format("mm:ss") })    
                        
           
        }
        
      
    }, [bandera])
        
    const handleChange = (event) => {
    
        setTema({...tema,estatus:event.target.checked?1:0});
        setValido(event.target.checked)
    };
    const onChange=(e)=>{                
        setTema({...tema,[e.target.name] : e.target.value})                        
    }

    const onChangeNum=(e)=>{  
      
        let expNopermitida = new RegExp('[A-Za-z$@#_&-+()/:;.!?,<>=]');
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');
        let expCadena = new RegExp('[0-9]');
        if (e.target.value.length <= 10 && (expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
            || e.target.value === ' ' || e.target.value === '') 
        {
            setTema({...tema,[e.target.name] : e.target.value})  
        }                     
    }

    const onChangeDate=(e)=>{
        if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined ) 
        {
          if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
          {
            setTema({...tema,[e.target.name] : e.target.value})                      
          }      
        }
      }


    const inputProps = {
       input:{fontSize: '5px'}
    };
    const Reloj=()=>{
        return<LocalizationProvider dateAdapter={AdapterMoment}>
   
          <TimePicker  
            ampmInClock={false} ampm={false}
            views={['minutes', 'seconds']}
            inputFormat="mm:ss"
            mask="__:__" disabled={activo} 
           // label="Minutes and seconds"
            value={value}
            onChange={(newValue) => {              
              setValue(newValue);
            }}
            renderInput={(params) => <TextField sx={{width:6.5+'rem'}} {...params} size="small"/>}
          />
   
      </LocalizationProvider>
  
    }
    function Renglon( ) {
        
        return (           
        <TableRow   className={classes.tableRow}    
            classes={{hover: classes.hover, selected: classes.selected}}            
            onClick={() => {  }}                     
        >   
        
            {  celda("center", { },       
                <div  sx={{justifyContent:"center"}}>
                    <Checkbox  checked={valido} size="small" onChange={handleChange} disabled={activo}/>
                </div>
            , 9, classes.tableCell)  }                                 
            {  celda("center", { },       
                <TextField disabled={activo} margin="none"
                   id="pista-campo" value={pista} name="pista"  
                    size="small" sx={{width:"3rem"}} onChange={onChangeNum}
                />
            , 1, classes.tableCell)  }                                    
            {celda("center", {}, 
               <TextField disabled={activo} margin="none"
                    id="isrc-campo" value={isrc} name="isrc"
                    inputProps={{style: {fontSize: 13}}} // font size of input text
                    InputLabelProps={{style: {fontSize: 13}}} // font size of input label
                    size="small" sx={{width:"8rem"}} onChange={onChange}
                />
            , 2, classes.tableCell)}  
            {celda("center", { }, 
                <TextField disabled={activo} margin="none"
                    id="ft-campo" value={ft} name="ft"
                    inputProps={{style: {fontSize: 13}}} // font size of input text
                    InputLabelProps={{style: {fontSize: 13}}} // font size of input label
                    size="small" sx={{width:"8rem"}} onChange={onChange}
                />
            , 3, classes.tableCell)}   
            {celda("center", { }, 
                <TextField     disabled={activo}    margin="none"        
                    id="date-fPublica" type="date" size='small'
                    value={fPublica} sx={{ width: 140,marginRight:.5+'rem' }} name="fPublica"
                    InputLabelProps={{ shrink: true }} onChange={onChangeDate}
                />
            , 4, classes.tableCell)}      
            {celda("left", {}, 
            <TextField disabled={activo} margin="none"
            id="titulo-campo" value={titulo} name="titulo"
            inputProps={{style: {fontSize: 13}}} // font size of input text
            InputLabelProps={{style: {fontSize: 13}}} // font size of input label
            size="small" sx={{width:"17rem"}} onChange={onChange}
        />
            , 5, classes.tableCell)}
            {celda("center", {},
            <TextField disabled={activo} margin="none"
            id="autor-campo" value={autor} name="autor" 
            inputProps={{style: {fontSize: 12}}} // font size of input text
            InputLabelProps={{style: {fontSize: 12}}} // font size of input label
            size="small" sx={{width:"16rem"}} onChange={onChange}
        />
            , 6, classes.tableCell)}            
            {celda("center", {}, 
            <TextField disabled={activo} margin="none"
            id="editora-campo" value={editora} name="editora"
            inputProps={{style: {fontSize: 12}}} // font size of input text
            InputLabelProps={{style: {fontSize: 12}}} // font size of input label
            size="small" sx={{width:"12rem"}} onChange={onChange}
        />, 7, classes.tableCell)}        
            {celda("center", {}, 
                Reloj() 
            , 8, classes.tableCell)}                          
        </TableRow>                          
        );
    }   
  return (
    Renglon()
  )
}

export default RegistroTema