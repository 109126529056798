import React,{useState,useEffect} from 'react'
import qs from 'qs';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {TextField,Stack ,MenuItem,FormControl,Select,InputLabel,
  Box, IconButton}from '@mui/material';
import FormDialog from '../../Layout/FormDialog'
import {llamadaApiToken,ErrorGeneral,llamadaApiCarga } from '../../funciones/ClienteAxios';
import {Search as SearchIcon} from '@mui/icons-material/';
import moment from 'moment';
import {toast} from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const NuevoAlbum = ({modalAbierto, setModalAbierto, registro,Buscar}) => {
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
  const history = useNavigate();
  const usuId =localStorage.getItem('UsuId') ; 
  const hoy=moment(new Date()).format('YYYY-MM-DD')
  const source = axios.CancelToken.source();            
  const [espera,setEspera]= useState(false);
  const [cargaInter,setCargaInter]= useState(false);
  const [cargaContra,setCargaContra]= useState(false);
  const [listTipo,setListTipo]= useState([])    
  const [listInterprete,setListInterprete]= useState([])    
  const [listContrato,setListContrato]= useState([])    
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)  
  const [contadorToast,setContadorToast]= useState(0)  
  const [contrato,setContrato]= useState({Tipo:'',     
    Interprete:null,
    ContratoId:null,            
    FAlta:hoy, 
    FPublica:hoy,//"2022-12-07T09:15:00",
    Cve:"",
    Upc:0,
    Codigo:"",
    Titulo:"",
    Version:"",auxInter:"",auxContrato:""
    })
  const{Tipo, Interprete,ContratoId ,FAlta,
    FPublica, Cve , Upc , Codigo , Titulo , Version,  auxInter, auxContrato  }=contrato

  useEffect(() => {

    const llenarListTipo = () => {      
      let data = qs.stringify({
        Usuario:usuId,
        idDispositivo:auxDispV
      });
      let url = "album-tipo";
      function respuesta(aux) {
        if (aux[0].Id !== -1) 
        {   setListTipo(aux);                
        }
      }
      
      llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)
    };    

    llenarListTipo()
   
    return () => {
      source.cancel();
    }

  }, [])
  
  const llamadaApi = () => {                   
    
    let data = qs.stringify({
        Tipo:Tipo,                 
        Interprete:Interprete,
        Contrato:ContratoId,        
        Usuario:usuId, 
        FAlta:`${FAlta}T00:00:00`,
        FPublica:`${FPublica}T00:00:00`,
        Cve:Cve,
        Upc:Upc,
        Codigo:Codigo,
        Titulo:Titulo,
        Version:Version,
        idDispositivo: auxDispV,
    });
    
    let url = "album-new";
    function respuesta(auxiliar){            
      if ( auxiliar) 
      {                 
        Buscar()
        let mensaje="Se creo correctamente el registro"        
        toast.success(`${mensaje}`,{
          pauseOnFocusLoss: false,
          theme: "colored", toastId: `exito-env`
        })  

        setModalAbierto(false)    
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setEspera,history);
  };  

  const busqInterprete = () => {                   
    
    let data = qs.stringify({              
        Usuario:usuId, 
        Cadena:auxInter,
        idDispositivo: auxDispV,
    });
    
    let url = "interprete-search";
    function respuesta(auxiliar){            
      if ( auxiliar[0].Id &&auxiliar[0].Id>0) 
      {                 
        setListInterprete(auxiliar)
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setCargaInter,history);
  };  
  const busqContrato = () => {                   
    
    let data = qs.stringify({              
        usuario:usuId, 
        cadena:auxContrato,
        idDispositivo: auxDispV,
    });
    
    let url = "contrato-search";
    function respuesta(auxiliar){            
      if ( auxiliar[0].Id &&auxiliar[0].Id>0) 
      {                 
        setListContrato(auxiliar)
      }     
        
    }
    llamadaApiCarga(data, url, respuesta,ErrorGeneral,setCargaContra,history);
  };  

  const onChangeDate=(e)=>{
    if (e.target.value!=="" && e.target.value!==null && e.target.value!==undefined ) 
    {
      if (e.target.value.length===10 && parseInt(e.target.value.substring(0,4 ))>=1900  ) 
      {
        if (e.target.name==="FIni" ) 
        {
          setContrato({...contrato,[e.target.name] : e.target.value,
            FFirma:e.target.value+"T09:00",
          })    
        }
        else
        { setContrato({...contrato,[e.target.name] : e.target.value})  
        }
        
      }      
    }
  }

  const onChange=(e)=>{
    
    if (e.target.name==="Tipo") 
    {
      setContrato({...contrato,[e.target.name] : e.target.value,Sello : ""})
    }
    else
    {
      setContrato({...contrato,[e.target.name] : e.target.value})
    }
    
  }

  const selectTipo= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
    <InputLabel id="demo-select-small">Tipo</InputLabel>
    <Select
      labelId="demo-select-small" id="demo-select-small" name="Tipo"
      value={Tipo} label="Tipo" onChange={onChange}
    >      
      {listTipo.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Alias}</MenuItem>)}
    </Select>
  </FormControl>  

const selectInter= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
    <InputLabel id="demo-select-small">Interprete</InputLabel>
    <Select
      labelId="Interprete-small" id="demo-select-small" name="Interprete"
      value={Interprete} label="Interprete" onChange={onChange}
    >      
      {listInterprete.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Nom}</MenuItem>)}
    </Select>
  </FormControl>  
  const selectContra= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
  <InputLabel id="demo-select-small">Contrato</InputLabel>
  <Select
    labelId="Contrato-small" id="demo-select-small" name="ContratoId"
    value={ContratoId} label="Contrato" onChange={onChange}
  >      
    {listContrato.map((tipo)=><MenuItem value={tipo.Id}>{`${tipo.Representa} - ${tipo.Sello}`}</MenuItem>)}
  </Select>
</FormControl>  

  const verificaNulos = (campo,descripcion)=>{
    let respuesta =false;              
    if( typeof campo !== 'number' && typeof campo === 'string' 
      && (campo.trim() === '' || campo.trim() === null) 
    ) 
    { let mensaje='No se lleno el campo '+descripcion      
      toast.warning(`${mensaje}`,{
        pauseOnFocusLoss: false, theme: "colored",
        toastId: `error-env ${contadorToast}`
      })  
      setContadorToast(contadorToast+1)
      respuesta =true
    }    
    return(respuesta);
  }

  const guardarDatos=()=>{    
    if ( !verificaNulos(Tipo,"Tipo")  && !verificaNulos(Interprete,"Interprete") 
      && !verificaNulos(ContratoId,"Contrato")  )      
    {      
      if (Titulo.length>=3 ||  !verificaNulos("","Titulo")) 
      {
        llamadaApi()        
      }
    }    
  }

  return (
  <FormDialog
    titulo="Nuevo Album" modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    tamanio="md" btnDeshabilitado={false} cargando={espera}
    setCargando={setEspera}
  >
    <Stack component="form" noValidate spacing={2} sx={{width:"28rem"}}>
      <Box>
        {listTipo.length!==0 ? selectTipo:null} 
      </Box>
      <TextField
        label="Titulo" id="Titulo-campo"
        value={Titulo} name="Titulo"
        size="small" sx={{width:"28rem"}} onChange={onChange}
      />
      <Box>
        <TextField
          label="Buscar Interprete" id="auxInter-campo"
          value={auxInter} name="auxInter"
          size="small" sx={{width:"10rem"}} onChange={onChange}
        />
       { cargaInter?<CircularProgress sx={{marginLeft:.5+'rem'}}/> 
        :<IconButton disabled={auxInter.length>=3? false:true} onClick={()=>busqInterprete()} >
          <SearchIcon />
        </IconButton>}
        {listInterprete.length!==0 ? selectInter:null} 
      </Box>
      <Box>
        <TextField
          label="Buscar Contrato" id="Contrato-campo"
          value={auxContrato} name="auxContrato"
          size="small" sx={{width:"10rem"}} onChange={onChange}
        />
       { cargaContra?<CircularProgress sx={{marginLeft:.5+'rem'}}/> 
        :<IconButton disabled={auxContrato.length>=3? false:true} onClick={()=>busqContrato()} >
          <SearchIcon />
        </IconButton>}
        {listContrato.length!==0 ? selectContra:null} 
      </Box>
      <Box >
        <TextField
          id="date-FAlta" label="Fecha Alta" type="date" size='small'
          value={FAlta} sx={{ width: 140,marginRight:.5+'rem' }} name="FAlta"
          InputLabelProps={{ shrink: true }} onChange={onChangeDate}
        />
        <TextField
          id="date-FPublica" label="Fecha Publica" type="date" size='small'
          value={FPublica} sx={{ width: 140,marginRight:.5+'rem' }} name="FPublica"
          InputLabelProps={{ shrink: true }} onChange={onChangeDate}
        />
        <TextField sx={{width:8+'rem'}}
          id="outlined-Version" label="Version"
          name="Version" onChange={onChange}
          value={Version} size='small'
        />
      </Box>   
      <Box>
        <TextField sx={{width:8+'rem',marginRight:1+'rem'}}
          id="outlined-cve" label="Cve"
          name="Cve" onChange={onChange}
          value={Cve} size='small'
        />
        <TextField sx={{width:8+'rem',marginRight:1+'rem'}}
          id="outlined-Upc" label="Upc"
          name="Upc" onChange={onChange}
          value={Upc} size='small'
        />
        <TextField sx={{width:10+'rem'}}
          id="outlined-Codigo" label="Codigo"
          name="Codigo" onChange={onChange}
          value={Codigo} size='small'
        />
      </Box> 
      
      
      
    </Stack>
  </FormDialog>
  )
}

export default NuevoAlbum